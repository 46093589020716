<template>
    <ContentBase>
      <div class="container">
        <div class="blog-post">
          <router-link :to="{ name: 'blog' }">返回</router-link>
          <h1 class="post-title">{{ post.title }}</h1>
          <p class="post-date">{{ post.date }}</p>
          <div class="post-content" v-html="renderedContent" ></div>
        </div>
      </div>
    </ContentBase>
  </template>
  
  <script>
  import ContentBase from '@/components/ContentBase.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { marked } from 'marked'; // 引入 marked
  import axios from 'axios';

  // 创建一个自定义的 marked 渲染器
  const renderer = new marked.Renderer();

  // 处理图片渲染
  renderer.image = (href, title, text) => {
    const imageUrl = typeof href === 'string' ? href : href.href;
    const imageAlt = typeof text === 'string' ? text : (text ? text.text : '');
    const imageTitle = title || '';

    return `<img src="${imageUrl}" alt="${imageAlt}" title="${imageTitle}" style="max-width: 80%;" />`;
  };
  // 自定义代码块渲染
  renderer.code = (code, language) => {
    const lang = language || 'csharp';
    // 这里的 `code` 是代码块的内容，`language` 是代码的语言（例如 'javascript'）
    console.log('code:', code);
    return `<pre style="text-align: left; min-width: 90%;background-color: #f5f5f5;border: 1px solid #ddd;border-radius: 4px;" ><code class="language-${lang}" style=" color: #333; text-align: left;">${code.raw}</code></pre>`;
  };



  export default {
    name: 'PostView',
    components: {
      ContentBase,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        post: {},
        renderedContent: '',
      };
    },
    methods: {
        fetchPostData() {
            axios.get(this.$apiBaseUrl + `post/getpost/${this.id}`)
                .then(response => {
                this.post = response.data;
                //this.renderedContent = marked(this.post.content);
                // 使用自定义的渲染器
                
                this.renderedContent = marked(this.post.content, { renderer });
                
                })
                .catch(error => {
                console.error("Error fetching post data:", error);
                });
        }
    },
    mounted() {
        this.fetchPostData();
    },
    setup() {
      const store = useStore();
      const themeClass = computed(() => store.getters.themeClass);
  
      return {
        themeClass
      };
    }
  }
  </script>


  <style scoped>
  .post-title {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .post-date {
    color: #888;
    margin-bottom: 20px;
  }
  
  .post-content {
    font-size: 1.2em;
    line-height: 1.6;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    overflow-wrap: break-word; /* 允许长单词换行 */
  }
  
  a {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    color: #747474;
    text-decoration: none;
  }

  </style>