<template>
    <ContentBase>
      <h1>{{ isRegister ? '注册' : '登录' }}</h1>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <div class="card card-custom" :class="themeClass">
              <div class="card-body">
                <form @submit.prevent="handleSubmit">
                  <div class="mb-3">
                    <label for="username" class="form-label">用户名</label>
                    <input v-model="username" type="text" class="form-control" id="username" />
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label">密码</label>
                    <input v-model="password" type="password" id="password" class="form-control" aria-describedby="passwordHelpBlock" />
                    <div id="passwordHelpBlock" class="form-text">
                      您的密码长度必须为 6-20 个字符，包含字母和数字，并且不得包含空格、特殊字符或表情符号。
                    </div>
                  </div>
                  <div v-if="isRegister">
                    <div class="mb-3">
                      <label for="repassword" class="form-label">确认密码</label>
                      <input v-model="repassword" type="password" id="repassword" class="form-control" />
                    </div>
                    <button :disabled="loading" type="submit" class="btn btn-primary btn-register">注册</button>
                  </div>
                  <div v-if="!isRegister">
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      <label class="form-check-label" for="exampleCheck1">验证</label>
                    </div>
                    <button :disabled="loading" type="submit" class="btn btn-primary btn-login">登录</button>
                  </div>
                  <div class="toggle-link" @click="toggleRegister" style="cursor: pointer;">
                    {{ isRegister ? '返回登录' : '注册' }}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentBase>
  </template>
  
  <script>
  import ContentBase from '@/components/ContentBase.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  
  export default {
    name: "LoginView",
    components: {
      ContentBase
    },
    data() {
      return {
        isRegister: false,
        username: '',
        password: '',
        repassword: '',
        loading: false
      };
    },
    methods: {
      toggleRegister() {
        this.isRegister = !this.isRegister;
      },
      async handleSubmit() {
        if (this.isRegister) {
          // 注册逻辑
          if (this.password !== this.repassword) {
            alert('密码和确认密码不匹配');
            return;
          }
          if (this.password.length < 6 || this.password.length > 20 || this.username.length < 2 || this.username.length > 15) {
            alert('密码长度必须为 6-20 个字符, 用户名长度必须为 2-15 个字符');
            return;
          }
  
          try {
            this.loading = true;
            const response = await axios.post(this.$apiBaseUrl + 'login/register', {
              username: this.username,
              password: this.password
            });
            if (response.data.success) {
              alert('注册成功');
              this.toggleRegister();
            }
          }  catch (error) {
            if (error.response) {
              // 请求已发出，但服务器响应了状态码在 2xx 之外
              alert('注册失败：' + error.response.data.message);
            } else {
              // 在设置请求时发生了错误
              console.error('注册错误', error);
              alert('注册失败：网络或服务器错误');
            }
          }finally {
            this.loading = false;
          }
        } else {
          // 登录逻辑
          if (this.password.length < 6 || this.password.length > 20 || this.username.length < 2 || this.username.length > 15) {
            alert('密码长度必须为 6-20 个字符, 用户名长度必须为 2-15 个字符');
            return;
          }
          try {
            this.loading = true;
            const response = await axios.post(this.$apiBaseUrl + 'login/login', {
              username: this.username,
              password: this.password
            });
            //console.log(response.data);
            if (response.data.success) {
              alert('登录成功'+response.data.message);
              // 执行登录成功后的操作，例如保存 token
              // 存储 token
              //localStorage.setItem('authToken', response.data.token);
              
              // 存储 JWT 令牌 并记录用户信息
              this.$store.dispatch('login', { user: response.data.user, token: response.data.token });  

            } else {
              
              alert('登录失败：' + response.data.message);
            }
          } catch (error) {
            console.error('登录错误', error);
            alert('登录失败，请重试');
          } finally {
            this.loading = false;
          }
        }
      },
    },
    setup() {
      const store = useStore();
      const themeClass = computed(() => store.getters.themeClass);
      
      return {
        themeClass,
        store
      };
    }
  };
  </script>
  
  <style scoped>
  .btn-login {
    width: 50%;
    margin-bottom: 10px;
  }
  
  .btn-register {
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .card-custom.bg-dark {
    background-color: rgb(150, 150, 150) !important;
  }
  
  .toggle-link {
    text-align: center;
    margin-top: 10px;
  }
  </style>
  