<template>
  <nav class="navbar navbar-expand-md bg-body-tertiary"   :class="themeClass">
  <div class="container">
    <router-link class="navbar-brand navbar-brand-style" :to="{name:'home'}">
        
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-unity icon-unity" viewBox="0 0 16 16">
        <path d="M15 11.2V3.733L8.61 0v2.867l2.503 1.466c.099.067.099.2 0 .234L8.148 6.3c-.099.067-.197.033-.263 0L4.92 4.567c-.099-.034-.099-.2 0-.234l2.504-1.466V0L1 3.733V11.2v-.033.033l2.438-1.433V6.833c0-.1.131-.166.197-.133L6.6 8.433c.099.067.132.134.132.234v3.466c0 .1-.132.167-.198.134L4.031 10.8l-2.438 1.433L7.983 16l6.391-3.733-2.438-1.434L9.434 12.3c-.099.067-.198 0-.198-.133V8.7c0-.1.066-.2.132-.233l2.965-1.734c.099-.066.197 0 .197.134V9.8L15 11.2Z"/>
        </svg>
        MyBlog
    </router-link>
    <button class="navbar-toggler btn" :class="themeClass" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
      </svg>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
          <li class="nav-item">
          <router-link class="nav-link active" aria-current="page" :to="{name:'home'}">首页</router-link>
          </li>
          <li class="nav-item">
          <router-link class="nav-link" :to="{name:'blog'}">博客</router-link>
          </li>
          <li class="nav-item">
          <router-link class="nav-link" :to="{name:'project'}">项目</router-link>
          </li>
          <li class="nav-item">
          <router-link class="nav-link" :to="{name:'guestbook'}">留言</router-link>
          </li>
          <li class="nav-item">
          <router-link class="nav-link" :to="{name:'about'}">关于</router-link>
          </li>
      </ul>
      <ul class="navbar-nav">
          <li class="nav-item">
            <div v-if="isLoggedIn" class="nav-link">{{user.username}}</div>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <router-link class="nav-link" :to="{name:'login'}">登录/注册</router-link>
          </li>
          <li class="nav-item" v-else>
            <button type="button" @click="logout" class="nav-link">退出</button>
          </li>
          <hr>
          <li class="nav-item">
            <button type="button" @click="toggleTheme" class="btn themebtn" :class="themeClass" aria-pressed="isDarkMode">
              切换主题
            </button>
          </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>

import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  name: "NavBar",
  setup() {
    const store = useStore();

   // 使用 getter 获取主题类名
   const themeClass = computed(() => store.getters.themeClass);
   const isLoggedIn = computed(() => store.getters.isLoggedIn);
   const user = computed(() => store.getters.user);

    // 使用 mutation 切换主题
    const toggleTheme = () => {
      store.commit('toggleTheme');
    };

    const logout = () => {
      
      store.commit('logout');
      
    };

    return {
      themeClass,
      toggleTheme,
      isLoggedIn,
      user,
      logout,
    };
  }
}
</script>

<style scoped>

.icon-unity{
  margin-bottom: 5px;
}

.navbar-brand-style {
  font-size: 28px; /* 调整品牌文字大小 */
  
}

.navbar.bg-light {
  background-color: #f8f9fa !important;
}

.navbar.bg-dark {
  background-color: #343a40 !important;
}


.navbar .nav-link {
  color: inherit; /* 使用继承的颜色 */
}

.navbar .nav-link.active {
  color: inherit; /* 使用继承的颜色 */
}

.btn.active {
  color: inherit; /* 使用继承的颜色 */
}

.navbar-brand{
  color: inherit;
}
.themebtn{
  padding: 7px 12px;
}


</style>