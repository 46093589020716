import { createStore } from 'vuex'


export default createStore({
  state() {
    return {
      isDarkMode: JSON.parse(localStorage.getItem('isDarkMode')) || false,
      isLoggedIn: false,
      user: null,
      userToken: null,
    };
  },
  mutations: {
    toggleTheme(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDarkMode', state.isDarkMode);
    },
    setLoggedIn(state, { isLoggedIn , info }) {
      state.isLoggedIn = isLoggedIn;
      state.userToken = info.token;
      state.user = info.user;
      //console.log(state.userToken,info.token,info.user,isLoggedIn);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userToken = null;
      state.user = null;
      //console.log(state.user);
    },
  },
  actions: {
    login({ commit },info) {
      commit('setLoggedIn', { isLoggedIn: true, info });
    },
    logout({ commit }) {
      commit('logout');
    },
  },
  getters: {
    themeClass(state) {
      return state.isDarkMode ? 'bg-dark text-white' : 'bg-light text-dark';
    },
    isLoggedIn: (state) => state.isLoggedIn,
    userToken: (state) => state.userToken,
    user: (state) => state.user,
  }
});