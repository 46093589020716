<template>
  <div class="timeline">
    <div class="timeline-item" v-for="event in sortedEvents" :key="event.id">
      <div class="timeline-marker"></div>
      <div class="timeline-content" :class="themeClass">
        <h5  >{{ event.title }}</h5>
        <p  >{{ event.content }}</p>
        <span class="timeline-date">{{ formatDate(event.date) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  name: 'TimelineComponent',
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedEvents() {
      return [...this.events].sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    }
  },
  setup() {
    const store = useStore();
    const themeClass = computed(() => store.getters.themeClass);

    return {
      themeClass
    };
  }
}
</script>

<style scoped>

.timeline-content.bg-dark{
  background-color: #343a40 !important;
  color: #fff !important;
}
.timeline-content.bg-light{
  background-color: #e1e7ee !important;
  color: #343a40 !important;
}

.timeline {
  position: relative;
  padding: 20px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #c5c5c5;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.timeline-item:nth-child(odd) .timeline-content {
  margin-right: auto;
  padding-right: 20px;
}

.timeline-item:nth-child(even) .timeline-content {
  margin-left: auto;
  padding-left: 20px;
}

.timeline-marker {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #c5c5c5;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.timeline-content {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline-date {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
</style>
