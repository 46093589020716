<script>
import ContentBase from '@/components/ContentBase.vue'
import TimelineComponent from '@/components/TimelineComponent.vue'
import axios from 'axios';
export default {
  name: 'AboutView',
  components: {
    ContentBase,
    TimelineComponent,
  },
  data() {
    return {
      personalInfo: {},
      events: [],
      personalInfoLoaded: false,
    };
  },
  mounted() {
    this.fetchPersonalInfo();
    this.fetchEvents();
  },
  methods: {
    async fetchPersonalInfo() {
      try {
        const response = await axios.get(this.$apiBaseUrl + 'about/personal-info');
        this.personalInfo = response.data;
        this.personalInfoLoaded = true;
      } catch (error) {
        console.error('Error fetching personal info:', error);
      }
    },
    async fetchEvents() {
      try {
        const response = await axios.get(this.$apiBaseUrl + 'about/events');
        this.events = response.data;
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    }
  }
}
</script>

<template>
  <ContentBase>
    <div class="about">
      <h1>个人简介</h1>
      <hr />
      <div class="row" v-if="personalInfoLoaded">
        <div class="col-md-3">
          <div class="self-introduction card-container">
            <div class="card mt-4" style="width: 16rem;">
              <img :src="personalInfo.profileImage" class="card-img-top" alt="头像">
              <div class="card-body">
                <h5 class="card-title">{{ personalInfo.name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ personalInfo.title }}</h6>
                <p class="card-text">{{ personalInfo.description }}</p>
                <p>联系信息:</p>
                <ul class="list-group list-group-flush list-text">
                  <li class="list-group-item">
                    Email: {{ personalInfo.contact.email }}
                  </li>
                  <li class="list-group-item">
                    Phone: {{ personalInfo.contact.phone }}
                  </li>
                  <li class="list-group-item">
                    Location: {{ personalInfo.contact.location }}
                  </li>
                </ul>
                
                <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                  查看更多
                </button>

                <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                  <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">
                      联系方式
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div class="offcanvas-body">
                    <p v-for="info in personalInfo.moreInfo" :key="info">{{ info }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
        <div class="col-md-9">
          <br><br>
          <p class="self-introduction-content font-monospace text-uppercase">
            {{ personalInfo.selfIntroduction }}
          </p>          
        </div>
      </div>
      <div v-else>
        加载中...
      </div>
      <hr />
      <div class="self-timeline">
        <h1>个人经历</h1>
        <TimelineComponent :events="events" />
      </div>
    </div>
  </ContentBase>
</template>



<style scoped>
.list-text{
  font-size: 12px;
  text-align: left;
}

.self-introduction-content{
  font-style:normal;
  font-size: 30px;
  text-align: left;
  padding: 10px;
  
}

.self-introduction{
  margin-bottom: 50px;
}

.self-timeline{
  margin-top: 50px;
  margin-bottom: 50px;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

