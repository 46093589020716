<script>
import ContentBase from '@/components/ContentBase.vue'
import { computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';


export default {
  name: 'BlogView',
  components: {
    ContentBase,
  },
  data() {
    return {
      blogPosts: [],
      currentPage: 1,
      postsPerPage: 10,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.blogPosts.length / this.postsPerPage);
    },
    paginatedPosts() {
      const start = (this.currentPage - 1) * this.postsPerPage;
      const end = start + this.postsPerPage;
      return this.blogPosts.slice(start, end);
    }
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
    },
    fetchBlogPosts() {
      axios.get(this.$apiBaseUrl + 'blog/blog-posts')
        .then(response => {
          this.blogPosts = response.data;
        })
        .catch(error => {
          console.error('Error fetching blog posts:', error);
        });
    }
  },
  mounted() {
    this.fetchBlogPosts();
  },
  setup() {
    const store = useStore();
    const themeClass = computed(() => store.getters.themeClass);

    return {
      themeClass
    };
  }
}
</script>

<template>
  <ContentBase>
    <div class="container ">
      <h1 class="blog-title text-start">简约博客页面</h1>
      <div class="blog">
        <div class="blog-list" >
          <div v-for="post in paginatedPosts" :key="post.id" class="blog-post">
            <div class="row">
              <div class="col-sm-auto">
                <div class="post-date text-start" :class="themeClass">{{ post.date }}</div>
              </div>
              <div class="col-sm-auto">
                <router-link :to="{ name: 'post', params: { id: post.id } }" class="post-title">{{ post.title }}</router-link>
              </div>
            </div> 
          </div>
        </div>
        <!-- 分页组件 -->
        <nav aria-label="Page navigation" class="page-navigation" >
          <ul class="pagination justify-content-center" >
            <li class="page-item" :class="{ disabled: currentPage === 1 }  ">
              <a class="page-link" :class="themeClass" href="#" aria-label="Previous" @click.prevent="changePage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item"  v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }">
              <a class="page-link" :class="themeClass" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" :class="themeClass" href="#" aria-label="Next" @click.prevent="changePage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </ContentBase>
</template>



<style scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-list {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}

.page-link.bg-dark{
  background-color: #576573 !important;
  color: #dfdede !important;
}


.page-navigation{
  margin-top: 40px;
}

.pagination {
  margin-top: 20px;
}

.page-item.active .page-link {
  background-color: #096d3b;
  border-color: #59bfb1;
  color: rgb(184, 184, 184);
}

.page-item.disabled .page-link {
  pointer-events: none;
  opacity: 0.6;
}




.blog{
  margin-top: 60px;
  margin-bottom: 20px;
}

.blog-post {
  margin-top: 10px;
}


.post-title {
  text-decoration: none;
  color: #0c9553;
  font-weight: bold;
  display: flex;
  justify-self: left;
}

.post-title:hover {
  text-decoration: underline;
}

.post-date.bg-dark{
  color: #ababab !important;
}



</style>

