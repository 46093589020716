<script>
import ContentBase from '@/components/ContentBase.vue'
import { computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import dentify from "@/components/Dentify";


export default {
  name: 'GuestbookView',
  components: {
    ContentBase,
    dentify,
  },
  data() {
    return {
      newMessage: {
        name: '',
        message: ''
      },
      isCaptchaVerified: false,
      messages: [],
      formsubmit: {
        code: "", //验证码输入框
      },
      identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
      identifyCode: "", //验证码图片内容
    };
  },
  methods: {
    async addMessage() {
      // console.log("验证码:", this.identifyCode);
      // console.log("用户输入的验证码:",this.formsubmit.code);
      // console.log('是否验证通过:',this.identifyCode==this.formsubmit.code);

      if(this.identifyCode != this.formsubmit.code){
        alert('验证码错误！');
        return;
      }

      if (this.newMessage.name && this.newMessage.message) {
        if (this.newMessage.message.length > 50) {
          alert('留言信息长度不能大于 50 个字符！！！');
          return;
        } else if (this.newMessage.message.length < 5) {
          alert('留言信息不能少于5个字符！！！');
          return;
        }

       // 验证成功后提交留言
       const postResponse = await axios.post(this.$apiBaseUrl + 'guestbook/add', this.newMessage);
          console.log(postResponse.data);

          this.messages.unshift({ ...this.newMessage, time: postResponse.data.time });
          console.log(this.messages);
          this.newMessage.name = '';
          this.newMessage.message = '';

      } else {
        alert('请填写所有必填字段！');
      }
    },
    async fetchMessages() {
      try {
        const response = await axios.get(this.$apiBaseUrl + 'guestbook/getall');
        console.log(response.data);
        this.messages = response.data;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
   // 重置验证码
   refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    //获取验证码的值
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        //通过循环获取字符串内随机几位
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    //随机数字：用于当角标拿字符串的值
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
  },
  mounted() {
    this.fetchMessages();
    // 初始化验证码
    this.identifyCode = "";
    //参数：（1）随机串内容。（2）验证码显示位数
    this.makeCode(this.identifyCodes, 4);
  },
  setup() {
    const store = useStore();
    const themeClass = computed(() => store.getters.themeClass);

    return {
      themeClass
    };
  }
}
</script>

<template>
  <ContentBase>
  <div class="guestbook">
    <h1 class="text-center mb-4">留言板</h1>
    <div class="row justify-content-md-left">
      <div class="col-md-3">
        <br>
        <div class="card"  :class="themeClass" >
          <div class="card-body">
            <form @submit.prevent="addMessage">
            <div class="mb-3">
              <label for="name" class="form-label">名字</label>
              <input type="text" class="form-control" :class="themeClass" id="name" v-model="newMessage.name" required>
            </div>
            <div class="mb-3">
              <label for="message" class="form-label">留言</label>
              <textarea class="form-control" id="message" :class="themeClass" rows="3" v-model="newMessage.message" required></textarea>
            </div>
            <div class="identify-submit" width="100%" @click="refreshCode">
              <!--验证码组件-->
              <dentify :identifyCode="identifyCode"></dentify>
            </div>
            
            <input type="text" v-model="formsubmit.code"  class="form-control input-identify" placeholder="请输入验证码" aria-label="请输入验证码" aria-describedby="basic-addon2" >
            <button type="submit" class="btn btn-primary" >提交留言</button>
          </form>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <hr>
        <div v-if="messages.length">
          <h3 class="text-center mb-4" :class="themeClass">留言列表</h3>
          
          <div class="list-group">

            <div class="list-group-item list-group-item-action" :class="themeClass"  v-for="(message, index) in messages" :key="index" >
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{message.name}}</h5>
                <small>{{message.time}}</small>
              </div>
              <p class="mb-1 item-text">
                {{message.message}}
              </p>
            </div>
            
            
          </div>

        </div>
        <div v-else>
          <p class="text-center mt-4">还没有留言，快来留下你的第一条留言吧！</p>
        </div>
      </div>
    </div>
  </div>
  </ContentBase>
</template>

<style scoped>
.card.bg-dark{
  background-color: #343a40 !important;
  color: #fff !important;
}

.card.bg-light{

  background-color: #f8fbff !important;
  color: #677079 !important;
}

.input-identify{
  margin-bottom: 10px;
}

.item-text{
  text-align: left;
  line-height:100%;
}

</style>

