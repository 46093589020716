<template>
    <div class="contentbase d-flex flex-column min-vh-100 text-center"  :class="themeClass">
        <div class="container text-center flex-grow-1">
            <slot></slot>

            
        </div>
        <footer class="blockquote-footer" :class="themeClass">
            <div class="copyright">
                <p>&copy; 2024 Yemo Jack. All rights reserved.</p>
                <p>All content on this website, including text, graphics, logos, and images, is the property of Yemo Jack and is protected by international copyright laws. Unauthorized use or reproduction of this content is prohibited without the express written consent of Yemo Jack.</p>
                <p>For any inquiries regarding the use of content on this website, please contact us at <a href="mailto:yemored@163.com">yemored@163.com</a>.</p>
                <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2024107240号-1</a>
                <br>
                <img src="@/assets/备案图标.png" alt="" style="width: 16px;">
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37152202000289" rel="noreferrer" target="_blank">鲁公网安备37152202000289</a>
            </div>
        </footer>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';




export default {
    name: 'ContentBase',
    setup() {
    const store = useStore();
    const themeClass = computed(() => store.getters.themeClass);

    return {
      themeClass
    };
  }
}
</script>

<style scoped>
 .container {
      margin-top: 10px;
      margin-bottom: 10px;
}


.background {
    width: 100vw;
    min-height: 100vh; /* 确保内容区域至少铺满整个视窗 */
}

.contentbase {
    position: relative;
    overflow: hidden; /* 确保内容不会溢出 */
}

.blockquote-footer{
    margin-top: 280px;
    margin-bottom: 10px;
}


/* 添加样式以确保版权声明美观 */
footer {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
  border-top: 1px solid #ddd;
}

footer a {
  color: #007bff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
</style>