import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProjectView from '../views/ProjectView.vue'
import BlogView from '../views/BlogView.vue'
import GuestbookView from '../views/GuestbookView.vue'
import PostView from '../views/PostView.vue'
import ShowProjectView from '@/views/ShowProjectView.vue'
import LoginView from '@/views/LoginView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import store from '../store'; // 引入 Vuex store

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:AboutView
  },
  {
    path: '/project',
    name: 'project',
    component: ProjectView, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/project/showproject/:id',
    name: 'showproject',
    component: ShowProjectView,
    props: true
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },
  {
    path: '/blog/post/:id',
    name: 'post',
    component: PostView,
    props: true
  },
  {
    path: '/guestbook',
    name: 'guestbook',
    component: GuestbookView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/404/',
    name: '404',
    component: NotFoundView
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果目标路由需要认证，并且用户未登录，则重定向到登录页面
    if (!isLoggedIn) {
      next({ name: 'login' });
    } else {
      next(); // 允许访问
    }
  } else {
    next(); // 允许访问
  }
});

export default router
