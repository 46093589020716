<template>
    <ContentBase>
      <div class="container">
        <div class="project-post">
            <router-link :to="{name:'project'}">返回</router-link>
          <h1 class="project-title">{{ project.title }}</h1>
          <p class="project-date">{{ project.date }}</p>
          <div class="project-description">{{ project.description }}</div>
          <div class="project-content" v-html="project.content"></div>
        </div>
      </div>
    </ContentBase>
</template>
  
<script>
import ContentBase from '@/components/ContentBase.vue';
import { marked } from 'marked'; // 引入 marked
import axios from 'axios';
import store from '../store'; // 引入 Vuex store

export default {
    name: 'ShowProjectView',
    components: {
        ContentBase,
    },
    props: {
        id: {
        type: String,
        required: true,
        },
    },
    data() {
        return {
        project: {},
        renderedContent: '',
        };
    },
    created() {
        this.fetchProjectData();
    },
    methods: {
    async fetchProjectData() {
        const token = store.state.userToken;
        console.log(token);

        try {
            const statusResponse = await axios.get(this.$apiBaseUrl + 'login/get_status', {
                headers: {
                    Authorization: `Bearer ${token}` // 将JWT令牌添加到请求头中
                }
            });

            console.log(statusResponse.data);
            if (statusResponse.data.result == 'login') {
                try {
                    const projectResponse = await axios.get(this.$apiBaseUrl + `show_project/getproject/${this.id}`);
                    this.project = projectResponse.data;
                    this.renderedContent = marked(this.project.content);
                } catch (error) {
                    console.error("Error fetching project data:", error);
                    alert('获取项目信息时出错');
                }
            } else {
                this.$router.push({ name: 'login' });
                alert('请重新登录');
            }
        } catch (error) {
            console.error("Error fetching user status:", error);
            alert('获取用户状态时出错,请登录后再试。');
        }
    }
}

};
</script>

<style scoped>
.project-title {
    font-size: 2em;
    margin-bottom: 10px;
}

.project-date {
    color: #888;
    margin-bottom: 20px;
}

.project-content {
    font-size: 1.2em;
    line-height: 1.6;
    display: flex;
    justify-content: left;
}

a{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    color: #747474;
    text-decoration: none;

}
</style>
  