
<script>
// @ is an alias to /src
import ContentBase from '@/components/ContentBase.vue'

export default {
  name: 'HomeView',
  components: {
    ContentBase,
  }
}
</script>

<template>
  <ContentBase>
  
    <div class="home">
    <div class="d-flex h-100 text-center ">
    <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
     

      <main class="px-3">
        <h1>Welcome To My Website.</h1>
        
        <p class="lead">
          欢迎来到我的个人博客网站！我是一名业余开发人员，目前正在大三阶段，对编程开发充满热情。在这里，您可以找到我的博客文章、开发项目、留言板以及关于我的信息。我的博客将分享我在编程与游戏开发方面的经验与见解，希望能为大家提供一些有趣的阅读内容。感谢您的光临，期待与您分享我的编程之旅！
        </p>
        
        
        <button type="button" class="btn btn-outline-secondary">Button</button>
        
      </main>

      
    </div>
  </div>
    </div>
  
  </ContentBase>
  
</template>

<style scoped>


.lead{
  font-size: 24px;
  margin-bottom: 100px;
}

h1 {
  margin: 50px 30px 30px 30px;
  display: flex;
  justify-content: center;

}




</style>