<script>
import ContentBase from '@/components/ContentBase.vue'
import { computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
  name: 'ProjectView',
  components: {
    ContentBase,
  },
  data() {
    return {
      projects: [],
    }
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await axios.get(this.$apiBaseUrl + 'project/getallproject');
        this.projects = response.data;
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    }
  },
  created() {
    this.fetchProjects();
  },
  setup() {
    const store = useStore();
    const themeClass = computed(() => store.getters.themeClass);

    return {
      themeClass
    };
  }
  }
</script>
<template>
  <ContentBase>
    <div class="container ">
      <h1 class="project-title text-center">简约项目页面</h1>
      <div class="project " >
        <div class="project-list">
          <router-link v-for="project in projects" class="no-underline" :key="project.id" :to="{ name: 'showproject', params: { id: project.id } }">
            <div class="project-item">
              <div class="card">
                <div class="card-body card-click" :class="themeClass">
                  <h5 class="card-title">{{ project.title }}</h5>
                  <div class="card-description">{{ project.description }}</div>
                  <div class="card-text text-lowercase">{{ project.date }}</div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      <!-- 分页组件 -->
      </div>
    </div>
  </ContentBase>
</template>

<style scoped>

.no-underline {
  text-decoration: none;
  color: inherit; /* Optional: maintain text color */
}

.card-click{
  cursor: pointer;
  transition: all 0.3s;
}

.card-text{
  font-size: 12px;
  text-align: right;
}
.card-title{
  text-align: left;
  margin-left: 20px;
  text-shadow: 1px 1px 1px #000000;

}
.card-description{
  text-align: left;
  margin-left: 20px;
  
}

.card{
  margin-top: 20px;
  margin-bottom: 10px;
}

.card-body{
  border-radius: 6px;
}
</style>

